var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.$store.getters['user/isRole']('super-admin') &&
        _vm.studentsInfo &&
        _vm.studentsInfo.length > 0
    )?[_c('Heading',{attrs:{"heading":"h4","content":"Students"}}),_c('hr'),_c('el-card',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.studentsInfo,"height":"500px"}},[_c('el-table-column',{attrs:{"label":"Name","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',[_c('router-link',{attrs:{"to":{
                  name: 'ProfileForAdmin',
                  params: { id: scope.row.id }
                }}},[_vm._v(" "+_vm._s(_vm.user.displayName(scope.row.first_name, scope.row.last_name))+" ")])],1)]}}],null,false,2749169716)}),_c('el-table-column',{attrs:{"label":"Email","width":"250px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.email)+" ")]}}],null,false,3438908753)}),_c('el-table-column',{attrs:{"label":"家長名字 + Email","width":"250px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.parents),function(parent){return _c('div',{key:parent.id},[_c('b',[_vm._v(_vm._s(_vm.user.displayName(parent.first_name, parent.last_name))+" ")]),_c('br'),_vm._v(" "+_vm._s(parent.email)+" ")])})}}],null,false,3703859291)}),_c('el-table-column',{attrs:{"label":"年級"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getGradeOnly( scope.row.student_profile.high_school_graduation_year ))+" ")]}}],null,false,3551286346)}),_c('el-table-column',{attrs:{"label":"學校"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.student_profile.high_school)+" ")]}}],null,false,2104809291)}),_c('el-table-column',{attrs:{"label":"合約"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.enrolled_session_id)?_c('router-link',{attrs:{"to":{
                name: 'EnrollmentDetail',
                params: {
                  id: scope.row.enrolled_session_id
                },
                query: {
                  view: 'contract'
                }
              }}},[_c('el-button',{attrs:{"type":"primary","size":"mini"}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" Preview ")])],1):_vm._e()]}}],null,false,2485567923)}),_c('el-table-column',{attrs:{"label":"備註事項"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.student_profile.taken_class_comments)+" ")]}}],null,false,2898109205)})],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }