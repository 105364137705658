<template>
  <div>
    <template
      v-if="
        $store.getters['user/isRole']('super-admin') &&
          studentsInfo &&
          studentsInfo.length > 0
      "
    >
      <Heading heading="h4" content="Students"></Heading>
      <hr />
      <el-card>
        <el-table :data="studentsInfo" style="width: 100%" height="500px">
          <el-table-column label="Name" width="180">
            <template slot-scope="scope">
              <b>
                <router-link
                  :to="{
                    name: 'ProfileForAdmin',
                    params: { id: scope.row.id }
                  }"
                >
                  {{
                    user.displayName(scope.row.first_name, scope.row.last_name)
                  }}
                </router-link>
              </b>
            </template>
          </el-table-column>
          <el-table-column label="Email" width="250px">
            <template slot-scope="scope">
              {{ scope.row.email }}
            </template>
          </el-table-column>
          <el-table-column label="家長名字 + Email" width="250px">
            <template slot-scope="scope">
              <div v-for="parent in scope.row.parents" :key="parent.id">
                <b
                  >{{ user.displayName(parent.first_name, parent.last_name) }}
                </b>
                <br />
                {{ parent.email }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="年級">
            <template slot-scope="scope">
              {{
                getGradeOnly(
                  scope.row.student_profile.high_school_graduation_year
                )
              }}
            </template>
          </el-table-column>
          <el-table-column label="學校">
            <template slot-scope="scope">
              {{ scope.row.student_profile.high_school }}
            </template>
          </el-table-column>
          <el-table-column label="合約">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.enrolled_session_id"
                :to="{
                  name: 'EnrollmentDetail',
                  params: {
                    id: scope.row.enrolled_session_id
                  },
                  query: {
                    view: 'contract'
                  }
                }"
              >
                <el-button type="primary" size="mini">
                  <i class="fa fa-eye"></i>
                  Preview
                </el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="備註事項">
            <template slot-scope="scope">
              {{ scope.row.student_profile.taken_class_comments }}
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";
import moment from "moment";
import { helper } from "@ivy-way/material";
import roleMixin from "@/mixins/role";
import profileTest from "@/mixins/profileTest";
import courseApi from "@/apis/course";

export default {
  metaInfo() {
    return {
      title: "Info"
    };
  },

  components: {},

  mixins: [roleMixin, profileTest],
  props: [],
  data() {
    return {
      studentsInfo: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    helper() {
      return helper;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    }
  },
  watch: {},

  mounted() {
    this.getSessions();
  },

  methods: {
    async getSessions() {
      const { session_class } = await courseApi.fetchSessionClass(
        this.$route.params.classId
      );
      this.students = session_class.student_class_tickets;

      const studentsInfo = await courseApi.getCourseStudentsInfo(
        this.$route.params.classId
      );
      this.studentsInfo = studentsInfo.students;

      this.studentsInfo.forEach(student => {
        this.students.forEach(item => {
          if (student.id === item.student.id) {
            student["enrolled_session_id"] = item.enrolled_session_id;
          }
        });
      });
    }
  }
};
</script>

<style scoped></style>
